import { useMemo } from "react";
import data from "../data";

const useNameDaySearch = (name?: string): { name: string; date: string }[] =>
  useMemo(
    () =>
      name
        ? Object.entries(data)
            .filter(([key]) => key.toLowerCase().includes(name.toLowerCase()))
            .map(([key, value]) => ({ name: key, date: value }))
            .sort(({ name: n }) => (n.toLowerCase().startsWith(name.toLowerCase()) ? -1 : 1))
        : [],
    [name]
  );

export default useNameDaySearch;
