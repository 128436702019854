import React from "react";

export default function Input(props: { label?: string } & JSX.IntrinsicElements["input"]) {
  const { label } = props;
  return (
    <div>
      <input type="text" className="bg-white p-2" {...props} />
      {label && <p className="text-sm ml-1 mt-1">{label}</p>}
    </div>
  );
}
