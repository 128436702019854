import React from "react";
import Paper from "./components/Paper";
import Search from "./components/Search";

function App() {
  return (
    <div className="flex w-full items-center justify-center pt-32">
      <div>
        <h1 className="text-3xl pb-4 text-center font-bold">Name day finder</h1>
        <Paper classNames="flex justify-center p-4">
          <Search />
        </Paper>
      </div>
    </div>
  );
}

export default App;
