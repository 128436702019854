import React, { useState } from "react";
import useNameDaySearch from "../hooks";
import Input from "./Input";
import Name from "./Name";

export default function Search() {
  const [name, setName] = useState<string | undefined>();
  const namedays = useNameDaySearch(name);

  const showResults = (name ?? "").length >= 3 || (namedays.length < 20 && name);

  return (
    <div>
      <Input
        placeholder="Name"
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        label={(name ?? "").length > 0 && !showResults ? "Enter atleast 3 characters!" : undefined}
      />
      {showResults && (
        <div className="mt-4">
          <h4 className="text-lg mb-2">Results</h4>
          {namedays.map((nameday) => (
            <Name {...nameday} />
          ))}
          {namedays.length === 0 && <p>No results!</p>}
        </div>
      )}
    </div>
  );
}
