const names: { [k: string]: string } = {
  Algo: "01.01",
  Alo: "01.01",
  Esmo: "01.01",
  Uno: "01.01",
  Uuno: "01.01",
  Algi: "02.01",
  Esme: "02.01",
  Esmi: "02.01",
  Gerhard: "03.01",
  Gert: "03.01",
  Keerdo: "03.01",
  Kert: "17.03",
  Ruth: "04.01",
  Rutt: "04.01",
  Lea: "05.01",
  Leana: "05.01",
  Liia: "05.01",
  Aabel: "06.01",
  Aabi: "06.01",
  Aabo: "06.01",
  Aap: "06.01",
  Aapo: "06.01",
  Hirvo: "07.01",
  Kanut: "07.01",
  Nuut: "07.01",
  Susi: "07.01",
  Gunnar: "08.01",
  Kunder: "08.01",
  Kunnar: "08.01",
  Veigo: "09.01",
  Veiko: "09.01",
  Veli: "09.01",
  Veljo: "09.01",
  Vello: "09.01",
  Velvo: "09.01",
  Venda: "09.01",
  Vendo: "09.01",
  Venno: "09.01",
  Väiko: "09.01",
  Talva: "10.01",
  Talve: "10.01",
  Talvi: "10.01",
  Osvald: "11.01",
  Ove: "11.01",
  Ande: "12.01",
  Antonia: "12.01",
  Tooni: "12.01",
  Hillar: "13.01",
  Hillo: "13.01",
  Illar: "13.01",
  Illart: "13.01",
  Illo: "13.01",
  Feliks: "14.01",
  Õnneleid: "14.01",
  Salve: "15.01",
  Salvi: "15.01",
  Sõlmi: "15.01",
  Illimar: "16.01",
  Ilmar: "16.01",
  Ilmo: "16.01",
  Anto: "17.01",
  Anton: "17.01",
  Antu: "17.01",
  Hando: "17.01",
  Tõnis: "17.01",
  Tõnn: "17.01",
  Tõnno: "17.01",
  Tõnu: "17.01",
  Lauli: "18.01",
  Laura: "18.01",
  Enn: "19.01",
  Enno: "19.01",
  Eno: "19.01",
  Heigo: "19.01",
  Heik: "19.01",
  Heiki: "19.01",
  Heiko: "19.01",
  Heino: "19.01",
  Hendrik: "19.01",
  Henn: "19.01",
  Henno: "19.01",
  Henri: "19.01",
  Henrik: "19.01",
  Hendrika: "20.01",
  Henna: "20.01",
  Henni: "20.01",
  Henriette: "20.01",
  Jete: "20.01",
  Age: "21.01",
  Agi: "21.01",
  Agne: "21.01",
  Agnes: "21.01",
  Agneta: "21.01",
  Aune: "21.01",
  Auni: "21.01",
  Ines: "21.01",
  Neta: "21.01",
  Luule: "22.01",
  Luuli: "22.01",
  Reeno: "23.01",
  Rene: "23.01",
  Räni: "23.01",
  Naima: "24.01",
  Naimi: "24.01",
  Paap: "25.01",
  Paavel: "25.01",
  Paavo: "25.01",
  Paul: "25.01",
  Ulve: "26.01",
  Ulvi: "26.01",
  Vilja: "27.01",
  Vilje: "27.01",
  Kaarel: "28.01",
  Kaarli: "28.01",
  Kaaro: "28.01",
  Kalle: "28.01",
  Karel: "28.01",
  Karl: "28.01",
  Karli: "28.01",
  Karro: "28.01",
  Valmo: "29.01",
  Valter: "29.01",
  Irja: "30.01",
  Irje: "30.01",
  Jürja: "30.01",
  Jürje: "30.01",
  Meeland: "31.01",
  Meelik: "31.01",
  Meelis: "31.01",
  Meelit: "31.01",
  Meelitu: "31.01",
  Meelo: "31.01",
  Meelu: "31.01",
  Birgit: "01.02",
  Birgitta: "01.02",
  Gita: "01.02",
  Piret: "01.02",
  Pireta: "01.02",
  Pirja: "01.02",
  Pirje: "01.02",
  Leegi: "02.02",
  Säde: "02.02",
  Hubert: "03.02",
  Hugo: "03.02",
  Huko: "03.02",
  Arma: "04.02",
  Armi: "04.02",
  Armiida: "04.02",
  Meida: "04.02",
  Miida: "04.02",
  Aet: "05.02",
  Agaate: "05.02",
  Aita: "05.02",
  Ita: "05.02",
  Iti: "05.02",
  Doora: "06.02",
  Doris: "06.02",
  Dorotea: "06.02",
  Tea: "06.02",
  Thea: "06.02",
  Richard: "07.02",
  Riho: "07.02",
  Riko: "07.02",
  Riku: "07.02",
  Taalo: "08.02",
  Tali: "08.02",
  Talis: "08.02",
  Talvo: "08.02",
  Mehis: "09.02",
  Mehto: "09.02",
  Ella: "10.02",
  Elle: "10.02",
  Ellen: "10.02",
  Elli: "10.02",
  Ellu: "10.02",
  Teri: "11.02",
  Terje: "11.02",
  Alma: "12.02",
  Alme: "12.02",
  Elma: "12.02",
  Elme: "12.02",
  Elmi: "12.02",
  Eke: "13.02",
  Ike: "13.02",
  Iko: "13.02",
  Valentin: "14.02",
  Valjo: "14.02",
  Valju: "14.02",
  Valle: "14.02",
  Vallo: "14.02",
  Vallot: "14.02",
  Vallut: "14.02",
  Neidi: "15.02",
  Tiina: "15.02",
  Alda: "16.02",
  Aldi: "16.02",
  Miralda: "16.02",
  Miranda: "16.02",
  Valda: "16.02",
  Velda: "16.02",
  Salmo: "17.02",
  Väino: "17.02",
  Väinu: "17.02",
  Karita: "18.02",
  Karme: "18.02",
  Karmen: "18.02",
  Rita: "18.02",
  Ülla: "19.02",
  Ülle: "19.02",
  Ülli: "19.02",
  Ülve: "19.02",
  Ülvi: "19.02",
  Ardi: "20.02",
  Hardi: "20.02",
  Hardo: "20.02",
  Kardo: "20.02",
  Meinhard: "20.02",
  Meino: "20.02",
  Aavo: "21.02",
  Auvo: "21.02",
  Avo: "21.02",
  Hela: "22.02",
  Heli: "22.02",
  Helin: "22.02",
  Helina: "22.02",
  Helis: "22.02",
  Häli: "22.02",
  Lehar: "23.02",
  Leho: "23.02",
  Lehto: "23.02",
  Madi: "24.02",
  Madis: "24.02",
  Maido: "24.02",
  Maidu: "24.02",
  Mait: "24.02",
  Mati: "24.02",
  Matis: "24.02",
  Mats: "24.02",
  Matti: "24.02",
  Mattias: "24.02",
  Mäido: "24.02",
  Tormi: "25.02",
  Tuule: "25.02",
  Tuuli: "25.02",
  Tuulike: "25.02",
  Ingmar: "26.02",
  Ingo: "26.02",
  Ingvar: "26.02",
  Selmar: "26.02",
  Helbe: "27.02",
  Helve: "27.02",
  Helvi: "27.02",
  Vilmar: "28.02",
  Vilmer: "28.02",
  Vilmo: "28.02",
  Ulmi: "29.02",
  Une: "29.02",
  Albin: "01.03",
  Albo: "01.03",
  Alvin: "01.03",
  Armin: "01.03",
  Virge: "02.03",
  Virgi: "02.03",
  Virma: "02.03",
  Virme: "02.03",
  Virve: "02.03",
  Virvi: "02.03",
  Egle: "03.03",
  Haili: "03.03",
  Halliki: "03.03",
  Helliki: "03.03",
  Almar: "04.03",
  Eimar: "04.03",
  Elmar: "04.03",
  Elmer: "04.03",
  Elmet: "04.03",
  Elmo: "04.03",
  Laila: "05.03",
  Laili: "05.03",
  Leila: "05.03",
  Leili: "05.03",
  Tarmo: "06.03",
  Tarmu: "06.03",
  Ralf: "07.03",
  Raul: "07.03",
  Rolf: "07.03",
  Rudolf: "07.03",
  Ruudi: "07.03",
  Ruut: "07.03",
  Lepo: "08.03",
  Viilo: "08.03",
  Viilup: "08.03",
  Vilbo: "08.03",
  Edvin: "09.03",
  Heido: "09.03",
  Heivo: "09.03",
  Helda: "10.03",
  Helde: "10.03",
  Heldi: "10.03",
  Aigar: "11.03",
  Ain: "11.03",
  Ainar: "11.03",
  Innar: "11.03",
  Inno: "11.03",
  Rego: "12.03",
  Reio: "12.03",
  Erni: "13.03",
  Ernits: "13.03",
  Erno: "13.03",
  Ernst: "13.03",
  Malde: "14.03",
  Maldi: "14.03",
  Matilde: "14.03",
  Meta: "14.03",
  Milda: "14.03",
  Milde: "14.03",
  Tilde: "14.03",
  Valev: "15.03",
  Valvik: "15.03",
  Valvo: "15.03",
  Heero: "16.03",
  Herbert: "16.03",
  Herbi: "16.03",
  Gerda: "17.03",
  Gertrud: "17.03",
  Kerli: "17.03",
  Kertu: "17.03",
  Kirli: "17.03",
  Kärdi: "17.03",
  Kärt: "17.03",
  Ruta: "17.03",
  Ruuta: "17.03",
  Truude: "17.03",
  Truuta: "17.03",
  Edi: "18.03",
  Eduard: "18.03",
  Eedi: "18.03",
  Eedo: "18.03",
  Eedu: "18.03",
  Joosep: "19.03",
  Joosu: "19.03",
  Malve: "20.03",
  Malvi: "20.03",
  Malviine: "20.03",
  Küllo: "21.03",
  Külvo: "21.03",
  Pendo: "21.03",
  Pent: "21.03",
  Viktor: "22.03",
  Viktoria: "22.03",
  Ailo: "23.03",
  Aksel: "23.03",
  Albe: "24.03",
  Albi: "24.03",
  Albina: "24.03",
  Maari: "25.03",
  Maarika: "25.03",
  Maarja: "25.03",
  Manni: "25.03",
  Mari: "25.03",
  Maria: "25.03",
  Marie: "25.03",
  Marika: "25.03",
  Marili: "25.03",
  Marja: "25.03",
  Marje: "25.03",
  Marjo: "25.03",
  Marju: "25.03",
  Imand: "26.03",
  Imant: "26.03",
  Immo: "26.03",
  Laide: "27.03",
  Laidi: "27.03",
  Leia: "27.03",
  Leida: "27.03",
  Leidi: "27.03",
  Arm: "28.03",
  Armas: "28.03",
  Armo: "28.03",
  Kallis: "28.03",
  Joakim: "29.03",
  Joonas: "29.03",
  Kimmo: "29.03",
  Pille: "30.03",
  Sibülle: "30.03",
  Hermi: "31.03",
  Härmi: "31.03",
  Irma: "31.03",
  Irmgard: "31.03",
  Irmi: "31.03",
  Harald: "01.04",
  Harri: "01.04",
  Harro: "01.04",
  Herald: "01.04",
  Ene: "02.04",
  Eneken: "02.04",
  Enel: "02.04",
  Eneli: "02.04",
  Enelin: "02.04",
  Uko: "03.04",
  Uku: "03.04",
  Ambrus: "04.04",
  Arbo: "04.04",
  Arp: "04.04",
  Arpo: "04.04",
  Ireene: "05.04",
  Irene: "05.04",
  Reena: "05.04",
  Reene: "05.04",
  Rena: "05.04",
  Renate: "05.04",
  Ville: "06.04",
  Villem: "06.04",
  Villi: "06.04",
  Villo: "06.04",
  Villu: "06.04",
  Allan: "07.04",
  Allo: "19.04",
  Julia: "08.04",
  Juuli: "08.04",
  Juulika: "08.04",
  Lia: "08.04",
  Liana: "08.04",
  Liane: "08.04",
  Iiris: "09.04",
  Kaira: "09.04",
  Kaire: "09.04",
  Kairi: "09.04",
  Hindrek: "10.04",
  Imre: "10.04",
  Indrek: "10.04",
  Ints: "10.04",
  Hurme: "11.04",
  Hurmi: "11.04",
  Urmi: "11.04",
  Julius: "12.04",
  Udo: "12.04",
  Ullo: "12.04",
  Ulrik: "12.04",
  Uudo: "12.04",
  Tarvi: "13.04",
  Tarvo: "13.04",
  Lehe: "14.04",
  Lehte: "14.04",
  Lehti: "14.04",
  Uljas: "15.04",
  Uljo: "15.04",
  Verner: "15.04",
  Verni: "15.04",
  Olivia: "16.04",
  Olve: "16.04",
  Olvi: "16.04",
  Ege: "17.04",
  Eike: "17.04",
  Hiie: "17.04",
  Valdek: "18.04",
  Valdeko: "18.04",
  Valdemar: "18.04",
  Valdo: "18.04",
  Valdu: "18.04",
  Valdur: "18.04",
  Valmar: "18.04",
  Valmer: "18.04",
  Voldemar: "18.04",
  Volli: "18.04",
  Volmer: "18.04",
  Aalike: "19.04",
  Aleksandra: "19.04",
  Alli: "19.04",
  Andra: "19.04",
  Sandra: "19.04",
  Orvi: "20.04",
  Urbe: "20.04",
  Urva: "20.04",
  Urve: "25.05",
  Urvi: "20.04",
  Aimar: "21.04",
  Aimer: "21.04",
  Aimo: "21.04",
  Aimur: "21.04",
  Meeri: "22.04",
  Meri: "22.04",
  Merike: "22.04",
  Merje: "22.04",
  Georg: "23.04",
  Jürgen: "23.04",
  Jürgo: "23.04",
  Jüri: "23.04",
  Jürjo: "23.04",
  Jürnas: "23.04",
  Jüts: "23.04",
  Ürjo: "23.04",
  Aada: "24.04",
  Iida: "24.04",
  Vaida: "24.04",
  Vanda: "24.04",
  Marek: "25.04",
  Margo: "25.04",
  Margus: "25.04",
  Mark: "25.04",
  Marko: "25.04",
  Markus: "25.04",
  Eesi: "26.04",
  Reesi: "26.04",
  Teesi: "26.04",
  Teisi: "26.04",
  Tereese: "26.04",
  Haldi: "27.04",
  Haldja: "27.04",
  Halja: "27.04",
  Lagle: "28.04",
  Luige: "28.04",
  Kalmer: "29.04",
  Kalmo: "29.04",
  Miia: "30.04",
  Mirja: "30.04",
  Mirjam: "30.04",
  Mirje: "30.04",
  Valba: "01.05",
  Valbe: "01.05",
  Valli: "01.05",
  Volber: "01.05",
  Maaja: "02.05",
  Mai: "02.05",
  Maia: "02.05",
  Maie: "02.05",
  Maiu: "02.05",
  Endel: "03.05",
  Endo: "03.05",
  Roosi: "04.05",
  Rosalie: "04.05",
  Saale: "04.05",
  Saali: "04.05",
  Sale: "04.05",
  Loit: "05.05",
  Loomet: "05.05",
  Aasa: "06.05",
  Aase: "06.05",
  Asse: "06.05",
  Helma: "07.05",
  Helme: "07.05",
  Helmeles: "07.05",
  Helmi: "07.05",
  Timmo: "08.05",
  Timmu: "08.05",
  Timo: "08.05",
  Kahru: "09.05",
  Ott: "09.05",
  Otto: "09.05",
  Aina: "10.05",
  Aini: "10.05",
  Ainike: "10.05",
  Aino: "10.05",
  Ainu: "10.05",
  Leevo: "11.05",
  Liivar: "11.05",
  Liivo: "11.05",
  Rünno: "12.05",
  Tapper: "12.05",
  Vootele: "12.05",
  Võitur: "12.05",
  Õie: "13.05",
  Õile: "13.05",
  Õili: "13.05",
  Õilme: "13.05",
  Edith: "14.05",
  Eede: "14.05",
  Eedit: "14.05",
  Sofia: "15.05",
  Viia: "15.05",
  Viiu: "15.05",
  Esta: "16.05",
  Este: "16.05",
  Ester: "16.05",
  Esti: "16.05",
  Taido: "17.05",
  Taidur: "17.05",
  Taimar: "17.05",
  Taimo: "17.05",
  Taivo: "17.05",
  Eeri: "18.05",
  Eerik: "18.05",
  Eero: "18.05",
  Ergi: "18.05",
  Ergo: "18.05",
  Erich: "18.05",
  Erik: "18.05",
  Erki: "18.05",
  Erko: "18.05",
  Emilie: "19.05",
  Emma: "19.05",
  Miili: "19.05",
  Milja: "19.05",
  Milla: "19.05",
  Milli: "19.05",
  Liili: "20.05",
  Liilia: "20.05",
  Lilian: "20.05",
  Lilja: "20.05",
  Lille: "20.05",
  Lilli: "20.05",
  Kindel: "21.05",
  Konstantin: "21.05",
  Kostel: "21.05",
  Tiino: "21.05",
  Leivo: "22.05",
  Oliver: "22.05",
  Lii: "23.05",
  Liidi: "23.05",
  Liidia: "23.05",
  Ly: "23.05",
  Lydia: "23.05",
  Alar: "24.05",
  Alari: "24.05",
  Allar: "24.05",
  Aller: "24.05",
  Urban: "25.05",
  Urbo: "25.05",
  Urves: "25.05",
  Miina: "26.05",
  Minna: "26.05",
  Valme: "26.05",
  Valmi: "26.05",
  Vella: "26.05",
  Velli: "26.05",
  Vilma: "26.05",
  Vilme: "26.05",
  Kalvi: "27.05",
  Klaudia: "27.05",
  Roman: "28.05",
  Roome: "28.05",
  Roomet: "28.05",
  Roomo: "28.05",
  Laido: "29.05",
  Leido: "29.05",
  Leidur: "29.05",
  Luulik: "29.05",
  Argo: "30.05",
  Arro: "30.05",
  Elga: "31.05",
  Helga: "31.05",
  Helge: "31.05",
  Helgi: "31.05",
  Helja: "31.05",
  Helje: "31.05",
  Heljo: "31.05",
  Helju: "31.05",
  Olga: "31.05",
  Olli: "31.05",
  Sireli: "01.06",
  Sirelin: "01.06",
  Sirle: "01.06",
  Sirli: "01.06",
  Veevi: "02.06",
  Viiva: "02.06",
  Viive: "02.06",
  Viivi: "02.06",
  Viivia: "02.06",
  Viivika: "02.06",
  Vivian: "02.06",
  Jolanta: "03.06",
  Kanni: "03.06",
  Kannike: "03.06",
  Viola: "03.06",
  Toivo: "04.06",
  Tõivo: "04.06",
  Tõivot: "04.06",
  Tõivotu: "04.06",
  Tõivu: "04.06",
  Vilimo: "05.06",
  Viljar: "05.06",
  Viljer: "05.06",
  Viljo: "05.06",
  Vilju: "05.06",
  Piibe: "06.06",
  Pärle: "06.06",
  Robert: "07.06",
  Robi: "07.06",
  Enda: "08.06",
  Endla: "08.06",
  Enna: "08.06",
  Enne: "08.06",
  Elar: "09.06",
  Haljand: "09.06",
  Hallar: "09.06",
  Helar: "09.06",
  Helari: "09.06",
  Hellar: "09.06",
  Amalie: "10.06",
  Maali: "10.06",
  Maila: "10.06",
  Maile: "10.06",
  Maili: "10.06",
  Mailis: "10.06",
  Imbi: "11.06",
  Imme: "11.06",
  Eskel: "12.06",
  Esko: "12.06",
  Monika: "13.06",
  Mooni: "13.06",
  Moonika: "13.06",
  Leina: "14.06",
  Leine: "14.06",
  Leini: "14.06",
  Guido: "15.06",
  Kuido: "15.06",
  Viido: "15.06",
  Viit: "15.06",
  Heelia: "16.06",
  Päive: "16.06",
  Päivi: "16.06",
  Eugen: "17.06",
  Kenno: "17.06",
  Auli: "18.06",
  Aurelia: "18.06",
  Auri: "18.06",
  Reeli: "18.06",
  Reelika: "18.06",
  Reili: "18.06",
  Sigrid: "19.06",
  Siiri: "19.06",
  Siivi: "19.06",
  Kaari: "20.06",
  Karlotte: "20.06",
  Karola: "20.06",
  Karoliine: "20.06",
  Karolin: "20.06",
  Lota: "20.06",
  Ahti: "21.06",
  Ahto: "21.06",
  Liina: "22.06",
  Liine: "22.06",
  Paula: "22.06",
  Pauliine: "22.06",
  Kalev: "23.06",
  Malev: "23.06",
  Malvo: "23.06",
  Annes: "24.06",
  Ants: "24.06",
  Hannes: "24.06",
  Hans: "24.06",
  Jaan: "24.06",
  Jan: "24.06",
  Janek: "24.06",
  Janno: "24.06",
  Johan: "24.06",
  Johannes: "24.06",
  Juhan: "24.06",
  Juho: "24.06",
  Jukk: "24.06",
  Juss: "24.06",
  Inna: "25.06",
  Lenna: "25.06",
  Linda: "25.06",
  Manivald: "26.06",
  Vaane: "26.06",
  Vaano: "26.06",
  Vaino: "26.06",
  Vane: "26.06",
  Vanevald: "26.06",
  Elfi: "27.06",
  Elfriide: "27.06",
  Elva: "27.06",
  Elve: "27.06",
  Elvi: "27.06",
  Elviira: "27.06",
  Viire: "27.06",
  Leo: "28.06",
  Leopold: "28.06",
  Peedo: "29.06",
  Peedu: "29.06",
  Peep: "29.06",
  Peet: "29.06",
  Peeter: "29.06",
  Helend: "30.06",
  Päivo: "30.06",
  Päivu: "30.06",
  Eha: "01.07",
  Ehala: "01.07",
  Hämarik: "01.07",
  Milve: "02.07",
  Milvi: "02.07",
  Arvo: "03.07",
  Aulik: "03.07",
  Virgo: "04.07",
  Virmo: "04.07",
  Virvo: "04.07",
  Kaja: "05.07",
  Kajar: "05.07",
  Sulev: "06.07",
  Sulo: "06.07",
  Koidu: "07.07",
  Koidula: "07.07",
  Eleonoora: "08.07",
  Ellinor: "08.07",
  Leonoora: "08.07",
  Loora: "08.07",
  Loore: "08.07",
  Noora: "08.07",
  Nora: "08.07",
  Aive: "09.07",
  Aivi: "09.07",
  Saima: "10.07",
  Saime: "10.07",
  Saimi: "10.07",
  Asko: "11.07",
  Askur: "11.07",
  Armand: "12.07",
  Herman: "12.07",
  Hermann: "12.07",
  Hermo: "12.07",
  Härm: "12.07",
  Härmel: "12.07",
  Härmo: "12.07",
  Greta: "13.07",
  Grete: "13.07",
  Kreet: "13.07",
  Kreeta: "13.07",
  Mare: "13.07",
  Maret: "13.07",
  Mareta: "13.07",
  Margareeta: "13.07",
  Marge: "13.07",
  Margit: "13.07",
  Marit: "13.07",
  Marita: "13.07",
  Meeta: "13.07",
  Reeda: "13.07",
  Reet: "13.07",
  Joel: "14.07",
  Joosua: "14.07",
  Ragne: "15.07",
  Raina: "15.07",
  Raine: "15.07",
  Raini: "15.07",
  Rein: "16.07",
  Reinhold: "16.07",
  Reino: "16.07",
  Reinu: "16.07",
  Reinut: "16.07",
  Rinaldo: "16.07",
  Ronald: "16.07",
  Meeme: "17.07",
  Meemo: "17.07",
  Eerika: "18.07",
  Erika: "18.07",
  Saara: "19.07",
  Saare: "19.07",
  Saari: "19.07",
  Salli: "19.07",
  Elias: "20.07",
  Erland: "20.07",
  Liias: "20.07",
  Tambet: "21.07",
  Tulev: "21.07",
  Leen: "22.07",
  Leena: "22.07",
  Leeni: "22.07",
  Made: "22.07",
  Madli: "22.07",
  Magda: "22.07",
  Magdaleena: "22.07",
  Mall: "22.07",
  Malle: "22.07",
  Saida: "23.07",
  Seida: "23.07",
  Senta: "23.07",
  Siina: "23.07",
  Kersti: "24.07",
  Kerstin: "24.07",
  Kirsti: "24.07",
  Krista: "24.07",
  Kristel: "24.07",
  Kristi: "24.07",
  Kristiina: "24.07",
  Kristin: "24.07",
  Kristina: "24.07",
  Rista: "24.07",
  Riste: "24.07",
  Jaagup: "25.07",
  Jaak: "25.07",
  Jaako: "25.07",
  Jaap: "25.07",
  Jako: "25.07",
  Jakob: "25.07",
  Jass: "25.07",
  Anete: "26.07",
  Anita: "26.07",
  Ann: "26.07",
  Anna: "26.07",
  Anne: "26.07",
  Anneli: "26.07",
  Anni: "26.07",
  Annika: "26.07",
  Anu: "26.07",
  Marta: "27.07",
  Marve: "27.07",
  Marvi: "27.07",
  Maasika: "28.07",
  Vaarika: "28.07",
  Olaf: "29.07",
  Olav: "29.07",
  Olavi: "29.07",
  Olev: "29.07",
  Kerman: "30.07",
  Kermo: "30.07",
  Roland: "30.07",
  Ave: "31.07",
  Meevi: "31.07",
  Maira: "01.08",
  Maire: "01.08",
  Mairi: "01.08",
  Maris: "01.08",
  Helger: "02.08",
  Helgo: "02.08",
  Holger: "02.08",
  Olger: "02.08",
  Kaljo: "03.08",
  Kalju: "03.08",
  Veera: "04.08",
  Veronika: "04.08",
  Veroonika: "04.08",
  Salme: "05.08",
  Salmi: "05.08",
  Laina: "06.08",
  Laine: "06.08",
  Lainela: "06.08",
  Laini: "06.08",
  Laive: "06.08",
  Laivi: "06.08",
  Hiljar: "07.08",
  Hiljo: "07.08",
  Vaido: "07.08",
  Vaigo: "07.08",
  Vaiko: "07.08",
  Silva: "08.08",
  Silve: "08.08",
  Silvi: "08.08",
  Silvia: "08.08",
  Deboora: "09.08",
  Imma: "09.08",
  Melita: "09.08",
  Mesike: "09.08",
  Laar: "10.08",
  Laur: "10.08",
  Lauri: "10.08",
  Laurits: "10.08",
  Lauro: "10.08",
  Sanna: "11.08",
  Sanne: "11.08",
  Susanna: "11.08",
  Suusi: "11.08",
  Klaara: "12.08",
  Klaarika: "12.08",
  Teedo: "13.08",
  Teedu: "13.08",
  Teet: "13.08",
  Teetlev: "13.08",
  Teeto: "13.08",
  Gisella: "14.08",
  Svea: "14.08",
  Hanna: "15.08",
  Jaana: "15.08",
  Jaanika: "15.08",
  Jana: "15.08",
  Janika: "15.08",
  Janne: "15.08",
  Jenny: "15.08",
  Johanna: "15.08",
  Nanna: "15.08",
  Aulis: "16.08",
  Aurel: "16.08",
  Kuldar: "16.08",
  Kullar: "16.08",
  Kullo: "16.08",
  Saamo: "17.08",
  Saamu: "17.08",
  Saamuel: "17.08",
  Elina: "18.08",
  Heleene: "18.08",
  Helen: "18.08",
  Helena: "18.08",
  Helene: "18.08",
  Hell: "18.08",
  Hella: "18.08",
  Helle: "18.08",
  Hellen: "18.08",
  Helli: "18.08",
  Ilona: "18.08",
  Maano: "19.08",
  Maanus: "19.08",
  Magnus: "19.08",
  Mango: "19.08",
  Mauno: "19.08",
  Benno: "20.08",
  Bernhard: "20.08",
  Pearn: "20.08",
  Pearu: "20.08",
  Pärn: "20.08",
  Pärno: "20.08",
  Päärn: "20.08",
  Pääro: "20.08",
  Sven: "21.08",
  Sveno: "21.08",
  Iivo: "22.08",
  Ivalo: "22.08",
  Ivar: "22.08",
  Ivari: "22.08",
  Ivo: "22.08",
  Signe: "23.08",
  Singe: "23.08",
  Albert: "24.08",
  Berta: "24.08",
  Pärt: "24.08",
  Pärtel: "24.08",
  Tauno: "25.08",
  Tunne: "25.08",
  Hilma: "26.08",
  Ilma: "26.08",
  Ilmatar: "26.08",
  Ilme: "26.08",
  Ilmi: "26.08",
  Maime: "27.08",
  Maimi: "27.08",
  Maimo: "27.08",
  Maimu: "27.08",
  August: "28.08",
  Gustav: "28.08",
  Kustas: "28.08",
  Kustav: "28.08",
  Kusti: "28.08",
  Kusto: "28.08",
  Õnne: "29.08",
  Õnnela: "29.08",
  Emil: "30.08",
  Meljo: "30.08",
  Mello: "30.08",
  Miljo: "30.08",
  Arv: "31.08",
  Arved: "31.08",
  Arvid: "31.08",
  Ülar: "01.09",
  Ülari: "01.09",
  Ülev: "01.09",
  Üllar: "01.09",
  Üllart: "01.09",
  Üllas: "01.09",
  Üllo: "01.09",
  Ülo: "01.09",
  Maive: "02.09",
  Maivi: "02.09",
  Taive: "02.09",
  Taivi: "02.09",
  Solveig: "03.09",
  Veegi: "03.09",
  Priide: "04.09",
  Priidika: "04.09",
  Priidla: "04.09",
  Preedik: "05.09",
  Priidik: "05.09",
  Priido: "05.09",
  Priidu: "05.09",
  Priit: "05.09",
  Reedik: "05.09",
  Vidrik: "05.09",
  Aiki: "06.09",
  Angela: "06.09",
  Angelika: "06.09",
  Ingel: "06.09",
  Regiina: "07.09",
  Reina: "07.09",
  Mariann: "08.09",
  Marianna: "08.09",
  Marianne: "08.09",
  Taidi: "09.09",
  Taima: "09.09",
  Taimi: "09.09",
  Lembe: "10.09",
  Lembi: "10.09",
  Lemme: "10.09",
  Lemmi: "10.09",
  Aalo: "11.09",
  Aleks: "11.09",
  Aleksander: "11.09",
  Sander: "11.09",
  Sanno: "11.09",
  Sass: "11.09",
  Meeli: "12.09",
  Meelike: "12.09",
  Meila: "12.09",
  Meili: "12.09",
  Melanie: "12.09",
  Mella: "12.09",
  Melli: "12.09",
  Ervi: "13.09",
  Ervin: "13.09",
  Ervo: "13.09",
  Raimo: "14.09",
  Raimond: "14.09",
  Raimu: "14.09",
  Raimund: "14.09",
  Reimo: "14.09",
  Reimu: "14.09",
  Kulmo: "15.09",
  Kulno: "15.09",
  Kurmo: "15.09",
  Kuulo: "15.09",
  Aila: "16.09",
  Aile: "16.09",
  Aili: "16.09",
  Alice: "16.09",
  Aliise: "16.09",
  Hilda: "17.09",
  Hildegard: "17.09",
  Hille: "17.09",
  Hilli: "17.09",
  Ille: "17.09",
  Illi: "17.09",
  Tiido: "18.09",
  Tiidrik: "18.09",
  Tiidu: "18.09",
  Tiit: "18.09",
  Erna: "19.09",
  Marna: "19.09",
  Kaubi: "20.09",
  Kaupo: "20.09",
  Lembit: "21.09",
  Lembitu: "21.09",
  Lembo: "21.09",
  Lemmert: "21.09",
  Lemmik: "21.09",
  Lemmo: "21.09",
  Marvo: "22.09",
  Maur: "22.09",
  Mauri: "22.09",
  Maurits: "22.09",
  Diana: "23.09",
  Dolores: "23.09",
  Tekla: "23.09",
  Alvar: "24.09",
  Alver: "24.09",
  Alvo: "24.09",
  Ago: "25.09",
  Agu: "25.09",
  Agur: "25.09",
  Ako: "25.09",
  Koido: "25.09",
  Koit: "25.09",
  Valve: "26.09",
  Valvi: "26.09",
  Velve: "26.09",
  Vilve: "26.09",
  Vilvi: "26.09",
  Elo: "27.09",
  Loone: "27.09",
  Õrne: "27.09",
  Lennart: "28.09",
  Lenno: "28.09",
  Leonhard: "28.09",
  Linnar: "28.09",
  Linnart: "28.09",
  Mihkel: "29.09",
  Miikael: "29.09",
  Mikk: "29.09",
  Miko: "29.09",
  Miku: "29.09",
  Kaur: "30.09",
  Kauri: "30.09",
  Tauri: "30.09",
  Ragnar: "01.10",
  Rain: "01.10",
  Rainer: "01.10",
  Raino: "01.10",
  Rauno: "01.10",
  Reinhard: "01.10",
  Leela: "02.10",
  Leeli: "02.10",
  Leelo: "02.10",
  Eevald: "03.10",
  Eevo: "03.10",
  Evald: "03.10",
  Evert: "03.10",
  Randel: "04.10",
  Rando: "04.10",
  Randolf: "04.10",
  Ranno: "04.10",
  Inga: "05.10",
  Inge: "05.10",
  Ingeborg: "05.10",
  Inger: "05.10",
  Ingi: "05.10",
  Ingrid: "05.10",
  Bruno: "06.10",
  Edmund: "06.10",
  Asso: "07.10",
  Ats: "07.10",
  Atso: "07.10",
  Hasso: "07.10",
  Hilja: "08.10",
  Hilje: "08.10",
  Hilju: "08.10",
  Mariina: "09.10",
  Riin: "09.10",
  Riina: "09.10",
  Riine: "09.10",
  Karmo: "10.10",
  Varmo: "10.10",
  Võso: "10.10",
  Aldo: "11.10",
  Aldur: "11.10",
  Eldur: "11.10",
  Haldo: "11.10",
  Haldur: "11.10",
  Heldur: "11.10",
  Aare: "12.10",
  Aaro: "12.10",
  Are: "12.10",
  Ebba: "13.10",
  Ebe: "13.10",
  Epp: "13.10",
  Kai: "14.10",
  Kaia: "14.10",
  Kaidi: "14.10",
  Kaie: "14.10",
  Kaili: "14.10",
  Kaisa: "14.10",
  Eda: "15.10",
  Ede: "15.10",
  Heda: "15.10",
  Hedi: "15.10",
  Hedvig: "15.10",
  Heidi: "15.10",
  Heivi: "15.10",
  Hädi: "15.10",
  Häidi: "15.10",
  Siret: "16.10",
  Sirja: "16.10",
  Sirje: "16.10",
  Vesta: "17.10",
  Veste: "17.10",
  Ludvig: "18.10",
  Lui: "18.10",
  Luukas: "18.10",
  Stella: "19.10",
  Tähte: "19.10",
  Tähti: "19.10",
  Jasper: "20.10",
  Kaspar: "20.10",
  Kasper: "20.10",
  Ulla: "21.10",
  Ulrika: "21.10",
  Ursula: "21.10",
  Anno: "22.10",
  Annus: "22.10",
  Hanno: "22.10",
  Hannus: "22.10",
  Ihan: "22.10",
  Ihanus: "22.10",
  Jaano: "22.10",
  Jaanus: "22.10",
  Leevi: "23.10",
  Liivi: "23.10",
  Liivia: "23.10",
  Liivika: "23.10",
  Asmo: "24.10",
  Asmus: "24.10",
  Ermo: "24.10",
  Rasmus: "24.10",
  Raivo: "25.10",
  Riivo: "25.10",
  Veevo: "25.10",
  Viivo: "25.10",
  Aime: "26.10",
  Aimi: "26.10",
  Amanda: "26.10",
  Ami: "26.10",
  Manda: "26.10",
  Eia: "27.10",
  Eila: "27.10",
  Eili: "27.10",
  Heili: "27.10",
  Häili: "27.10",
  Hälli: "27.10",
  Siim: "28.10",
  Siimo: "28.10",
  Siimon: "28.10",
  Siimu: "28.10",
  Simmo: "28.10",
  Simmu: "28.10",
  Simun: "28.10",
  Alf: "29.10",
  Alfred: "29.10",
  Fred: "29.10",
  Fredi: "29.10",
  Urmas: "30.10",
  Urmet: "30.10",
  Urmo: "30.10",
  Ardo: "31.10",
  Ardu: "31.10",
  Arti: "31.10",
  Arto: "31.10",
  Artur: "31.10",
  Tiia: "01.11",
  Tiiu: "01.11",
  Hinge: "02.11",
  Hingi: "02.11",
  Kaido: "03.11",
  Kaidu: "03.11",
  Kaimar: "03.11",
  Kaimo: "03.11",
  Kairo: "03.11",
  Kaivo: "03.11",
  Erla: "04.11",
  Erle: "04.11",
  Herta: "04.11",
  Vaho: "05.11",
  Vahto: "05.11",
  Vahur: "05.11",
  Aadi: "06.11",
  Aado: "06.11",
  Aadu: "06.11",
  Ado: "06.11",
  Adolf: "06.11",
  Kiira: "07.11",
  Kiiri: "07.11",
  Kirke: "07.11",
  Nele: "08.11",
  Nella: "08.11",
  Nelli: "08.11",
  Teo: "09.11",
  Teodor: "09.11",
  Tuudor: "09.11",
  Mardi: "10.11",
  Mardo: "10.11",
  Mart: "10.11",
  Martin: "10.11",
  Märt: "10.11",
  Märten: "10.11",
  Alev: "11.11",
  Elev: "11.11",
  Elvo: "11.11",
  Konrad: "12.11",
  Kuno: "12.11",
  Kuuno: "12.11",
  Krister: "13.11",
  Kristjan: "13.11",
  Kristo: "13.11",
  Risto: "13.11",
  Alve: "14.11",
  Alvi: "14.11",
  Alviine: "14.11",
  Vaige: "15.11",
  Vaigi: "15.11",
  Vaike: "15.11",
  Vaiki: "15.11",
  Aarne: "16.11",
  Arne: "16.11",
  Arno: "16.11",
  Arnold: "16.11",
  Egil: "17.11",
  Egon: "17.11",
  Einar: "17.11",
  Einari: "17.11",
  Eino: "17.11",
  Heinar: "17.11",
  Ilo: "18.11",
  Ilu: "18.11",
  Betti: "19.11",
  Eliisabet: "19.11",
  Eliise: "19.11",
  Elis: "19.11",
  Els: "19.11",
  Elsa: "19.11",
  Else: "19.11",
  Ilse: "19.11",
  Liis: "19.11",
  Liisa: "19.11",
  Liisi: "19.11",
  Liisu: "19.11",
  Helmar: "20.11",
  Helmer: "20.11",
  Helmo: "20.11",
  Helmu: "20.11",
  Helmur: "20.11",
  Helmut: "20.11",
  Pilve: "21.11",
  Pilvi: "21.11",
  Cecilia: "22.11",
  Silja: "22.11",
  Silje: "22.11",
  Sille: "22.11",
  Säsil: "22.11",
  Kleement: "23.11",
  Leemet: "23.11",
  Leemo: "23.11",
  Ustav: "24.11",
  Ustus: "24.11",
  Kaarin: "25.11",
  Kadi: "25.11",
  Kadri: "25.11",
  Kadrin: "25.11",
  Karin: "25.11",
  Katariina: "25.11",
  Kati: "25.11",
  Katre: "25.11",
  Katri: "25.11",
  Katrin: "25.11",
  Triin: "25.11",
  Triina: "25.11",
  Triinu: "25.11",
  Dagmar: "26.11",
  Maara: "26.11",
  Maare: "26.11",
  Tamaara: "26.11",
  Asta: "27.11",
  Astra: "27.11",
  Astrid: "27.11",
  Laima: "28.11",
  Niina: "28.11",
  Raima: "28.11",
  Edgar: "29.11",
  Egert: "29.11",
  Andi: "30.11",
  Ando: "30.11",
  Andre: "30.11",
  Andreas: "30.11",
  Andres: "30.11",
  Andro: "30.11",
  Andrus: "30.11",
  Andu: "30.11",
  Anti: "30.11",
  Oskar: "01.12",
  Osmar: "01.12",
  Oss: "01.12",
  Aira: "02.12",
  Aire: "02.12",
  Airi: "02.12",
  Leiger: "03.12",
  Leino: "03.12",
  Barba: "04.12",
  Barbara: "04.12",
  Parba: "04.12",
  Varje: "04.12",
  Varju: "04.12",
  Varve: "04.12",
  Selma: "05.12",
  Selme: "05.12",
  Klaus: "06.12",
  Laas: "06.12",
  Laus: "06.12",
  Nigul: "06.12",
  Nigulas: "06.12",
  Niilas: "06.12",
  Niilo: "06.12",
  Nikolai: "06.12",
  Nils: "06.12",
  Piine: "07.12",
  Sabiine: "07.12",
  Külli: "08.12",
  Küllike: "08.12",
  Külliki: "08.12",
  Külve: "08.12",
  Külvi: "08.12",
  Raid: "09.12",
  Raido: "09.12",
  Raidu: "09.12",
  Raigo: "09.12",
  Raik: "09.12",
  Raiko: "09.12",
  Rait: "09.12",
  Juta: "10.12",
  Juudit: "10.12",
  Daaniel: "11.12",
  Taaniel: "11.12",
  Taano: "11.12",
  Tanel: "11.12",
  Tani: "11.12",
  Tanno: "11.12",
  Aivar: "12.12",
  Aiver: "12.12",
  Aivo: "12.12",
  Ele: "13.12",
  Ere: "13.12",
  Hele: "13.12",
  Loviise: "13.12",
  Lucia: "13.12",
  Luise: "13.12",
  Viise: "13.12",
  Eho: "14.12",
  Hengo: "14.12",
  Hingo: "14.12",
  Kalli: "15.12",
  Kelli: "15.12",
  Killu: "15.12",
  Kulla: "15.12",
  Aade: "16.12",
  Aale: "16.12",
  Adeele: "16.12",
  Adelheid: "16.12",
  Aliide: "16.12",
  Ethel: "16.12",
  Haide: "16.12",
  Liide: "16.12",
  Teele: "16.12",
  Rahel: "17.12",
  Raili: "17.12",
  Neeme: "18.12",
  Neemo: "18.12",
  Maarius: "19.12",
  Mairo: "19.12",
  Mario: "19.12",
  Pärja: "20.12",
  Pärje: "20.12",
  Tom: "21.12",
  Tommi: "21.12",
  Toom: "21.12",
  Toomas: "21.12",
  Vambo: "22.12",
  Vambola: "22.12",
  Eugenia: "23.12",
  Senna: "23.12",
  Senni: "23.12",
  Aadam: "24.12",
  Eeva: "24.12",
  Eevi: "24.12",
  Eva: "24.12",
  Eve: "24.12",
  Eveli: "24.12",
  Evelin: "24.12",
  Evi: "24.12",
  Iivi: "24.12",
  Ivi: "24.12",
  Ivika: "24.12",
  Tammo: "24.12",
  Tamur: "24.12",
  Natalie: "25.12",
  Neida: "25.12",
  Taale: "25.12",
  Taali: "25.12",
  Sten: "26.12",
  Taban: "26.12",
  Tahvo: "26.12",
  Teho: "26.12",
  Tehvan: "26.12",
  Tehvo: "26.12",
  Sula: "27.12",
  Sulve: "27.12",
  Sulvi: "27.12",
  Piia: "28.12",
  Piiu: "28.12",
  Merili: "29.12",
  Merle: "29.12",
  Merli: "29.12",
  Mirle: "29.12",
  Taave: "30.12",
  Taavet: "30.12",
  Taavi: "30.12",
  Taavo: "30.12",
  Tavo: "30.12",
  Silvar: "31.12",
  Silver: "31.12",
  Silvester: "31.12",
  Silvo: "31.12",
};

export default names;
