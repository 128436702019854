import { format } from "date-fns";
import React, { useMemo } from "react";

export default function Name({ name, date }: { name: string; date: string }) {
  const link = "https://www.stat.ee/nimed/" + name;

  const dateValue = useMemo(() => {
    const [day, month] = date.split(".");
    const dateObj = new Date(`${month}-${day}-2021`);

    return format(dateObj, "d. MMMM");
  }, [date]);

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className="p-2 bg-gray-50 hover:bg-gray-100 flex justify-between">
        <span>{name}</span>
        <span>{dateValue}</span>
      </div>
    </a>
  );
}
